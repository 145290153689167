html,
body {
	min-height: 100vh;
}
body {
	min-width: 800px;
	// min-height: 500px;
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
}

code {
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
